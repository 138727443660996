import React from 'react';
import styles from './CardFlair.module.scss';
import Image from 'next/image';

interface Props {
  imageUrl?: string,
}

const CardFlairStatic: React.FC<Props> = ({
  imageUrl,
}) => {
  return (
    <span
      className={`${styles.staticFlair}`}
    >
      <Image
        src = {imageUrl!}
        alt={''}
        width={60}
        height={60}
      />
    </span>
  );
};

export default CardFlairStatic;
