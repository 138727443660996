import crypto from 'crypto';

import { PbsKidsTheme } from '@/types/pbskids-graph';

import {
  filterOutUselessThemeValues,
  getCssVariableDeclarationsFromThemeContext,
  isEmptyThemeValue,
  isImageField,
  singleTransparentPixel,
  sortThemeKeysToMatchDefault,
} from '.';

export type ModuleBackgroundType = 'image' | 'pattern';

const deepClone = (obj: object) => JSON.parse(JSON.stringify(obj));

const sha256Synchronous = (content: string) => {
  return crypto.createHash('sha256').update(content).digest('hex');
};

const getModuleThemeContextId = (theme: PbsKidsTheme, backgroundMode: ModuleBackgroundType) => {
  return sha256Synchronous(JSON.stringify({
    backgroundMode,
    theme,
  })).substring(0, 8);
};

const isAllowedImageField = (fieldName: string, backgroundMode: ModuleBackgroundType) => {
  return (
    ( backgroundMode === 'image' && fieldName === 'backgroundImage' ) ||
    ( backgroundMode === 'pattern' && fieldName === 'backgroundPattern' )
  );
};

const getModuleThemeCss = (theme: PbsKidsTheme, backgroundMode: ModuleBackgroundType) => {
  const themeValues = deepClone(theme);

  for (const key of Object.keys(themeValues)) {
    if (
      isImageField(key) &&
      (
        isEmptyThemeValue(themeValues[key]) ||
        !isAllowedImageField(key, backgroundMode)
      )
    ) {
      // Use an empty single-pixel image if it's an image field that's not allowed in curent module.
      themeValues[key] = singleTransparentPixel;
    }
  }

  const cssThemeValues = filterOutUselessThemeValues(
    sortThemeKeysToMatchDefault(
      themeValues,
    ),
  );

  return getCssVariableDeclarationsFromThemeContext(
    cssThemeValues,
    'module',
    `[data-theme-module-contextid='${getModuleThemeContextId(theme, backgroundMode)}']`,
  );
};

export {
  getModuleThemeContextId,
  getModuleThemeCss,
};
