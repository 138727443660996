import React, { useRef, useState, SyntheticEvent, TouchEvent } from 'react';
import Image from 'next/image';
import Pagination from '@/components/base/Pagination';
import { Game } from '@/types/pbskids-graph';
import LookBadge from '@/public/puma/images/LookBadge.svg';
import LookPlayArrow from '@/public/puma/images/LookPlayArrow.svg';
import { singleTransparentPixel } from '@/utils/theming-system';
import styles from './LookInsideCard.module.scss';

interface Props {
  game: Game
  classes?: string,
  sizes?: string
}

const LookInsideCard: React.FC<Props> = ({ game, classes, sizes }) => {
  const [ visibleSlide, setVisibleSlide ] = useState<number>(0);
  const [ isRotating, setIsRotating ] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const currIteration = useRef<number>(0);
  const currSlide = useRef<number>(0);
  const iterationLimit = 3;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const slides = game.lookInsideAssets || [];
  const slidePause = 2000;
  const slideInterval = useRef<ReturnType<typeof setInterval>|null>(null);
  const totalSlides = slides.length;

  const endLookInside = () => {
    slideInterval.current && clearInterval(slideInterval.current);
    slideInterval.current = null;
    setIsRotating(false);
    currIteration.current = 0;
    currSlide.current = 0;
    setVisibleSlide(0);
  };

  const animateSlides = () => {
    if (currIteration.current === iterationLimit && (currSlide.current === totalSlides -1)) {
      endLookInside();
      return;
    }

    currSlide.current = (currSlide.current + 1) % totalSlides;
    setVisibleSlide(currSlide.current);
    currSlide.current === 0 && currIteration.current++;
  };

  const startLookInside = () => {
    if (!isRotating) {
      setIsRotating(true);
      currIteration.current = 1;
      slideInterval.current = setInterval(animateSlides, slidePause);
    }
  };

  const onCardClick = (ev: SyntheticEvent) => {
    if (ev.target !== linkRef.current) {
      linkRef.current?.click();
    }
  };

  const onLinkClick = (ev: SyntheticEvent) => {
    ev.stopPropagation();
    endLookInside();
  };

  const onTouchEnd = (ev: TouchEvent) => {
    if (!isRotating) {
      startLookInside();
      ev.preventDefault();
    }
  };

  if (!slides.length || !game.websiteUrl || !game.title) {
    return null;
  }

  return (
    <article
      ref={cardRef}
      data-pbsk-component-look-inside-card
      data-use-loading-dots
      className={`${styles.lookInsideCard} ${isRotating ? styles.activeCard : ''}`}
      onClick={onCardClick}
      onMouseEnter={startLookInside}
      onMouseLeave={endLookInside}
      onTouchEnd={onTouchEnd}
    >
      <div
        data-ga-click-ignore
        className={`${styles.cardSlides} ${classes}`}
      >
        <div className={`${styles.cardSlide} ${isRotating ? '' : styles.currentSlide} ${classes}`}>
          <Image
            src={ game.mezzanine?.[0]?.url || singleTransparentPixel }
            alt={ game.title }
            sizes={ sizes || '100vw'}
            width={ 908 }
            height={ 510 }
          />
        </div>
        { slides.map((slide, index) => (
          <div
            key={index}
            className={`${styles.cardSlide} ${isRotating && index === visibleSlide ? styles.currentSlide : ''} ${classes}`}>
            <Image
              src={ slide?.url || singleTransparentPixel }
              alt=''
              sizes={ sizes || '100vw'}
              width={ slide?.width || 908 }
              height={ slide?.height || 510 }
            />
          </div>
        ))}
      </div>
      <div className={styles.cardFooter}>
        { isRotating ? (
          <Pagination
            current={visibleSlide}
            slides={slides}
            wrapperStyles={styles.paginationWrapper}
          />
        ) : (
          <div className={styles.lookBadge}>
            <LookBadge title='look' />
          </div>
        )}
        {/* Important that this link stays in the DOM even if
        it's hidden, so that it's picked up on a screen reader's
        list of links, and so that the card can be tabbed to.*/}
        <a
          ref={linkRef}
          data-ga-click-delay
          className={styles.playButton + ' ' + (isRotating ? styles.playButtonActive : '')}
          href={game.websiteUrl}
          rel='noopener noreferrer'
          aria-label={'Play ' + game.title }
          onBlur={endLookInside}
          onFocus={startLookInside}
          onClick={onLinkClick}
        >
          <LookPlayArrow />
        </a>
      </div>
    </article>
  );
};

export default LookInsideCard;
