import React, { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import CardFlairStatic from '@/components/base/CardFlair/CardFlairStatic';
import LookInsideCard from '@/components/modules/LookInsideCard';
import { Game, PbsKidsFlair } from '@/types/pbskids-graph';
import { singleTransparentPixel } from '@/utils/theming-system';
import styles from './GameCard.module.scss';

interface Props {
  enableLookInside?: boolean,
  classes?: string,
  gameItem: Game,
  sizes?: string,
  inMasthead?: boolean,
}

const displayFlair = (flair: PbsKidsFlair): ReactNode => {
  // TODO: Currently we're assuming that flairs are only static images and we're choosing the
  // first UI asset as the that image. We should update this to select the appropriate UI asset
  // for the different Flair types. For static images we should prioritize SVG assets, then WebP
  // then PNG, then JPG (maybe), and reject other types. The NextJS image optimization tool will
  // still transform the WebP and PNG images, but WebP is a better source to start with.
  if (flair.uiAssets?.[0]?.asset?.[0]?.url) {
    return (
      <div className={styles.staticFlairWrapper}>
        <CardFlairStatic imageUrl={flair.uiAssets[0].asset[0].url}/>
      </div>
    );
  }
};

const GameCard: React.FC<Props> = ({
  enableLookInside = true,
  classes = '',
  gameItem,
  sizes,
}) => {
  // No Game, No Render.
  if (!gameItem) {
    return null;
  }

  const linkUrl = gameItem.websiteUrl || '';
  const imageUrl = gameItem.mezzanine?.[0]?.url;
  const alt = gameItem.mezzanine?.[0]?.altText || gameItem.title || '';

  // No Link nor Image, No Render.
  if (!linkUrl || !imageUrl) {
    return null;
  }

  // Render LookInside Card.
  if (enableLookInside && gameItem.lookInsideAssets && gameItem.lookInsideAssets?.length > 0) {
    return <LookInsideCard
      game={ gameItem }
      sizes={sizes || '300px'}
      classes={ classes }
    />;
  }

  const hasFlair = !!gameItem.flair?.[0]?.uiAssets?.[0]?.asset?.[0]?.url;

  // Default to general Game Card.
  return (
    <article
      data-hover-group='game-card'
      className={styles.gameCard}>
      <div data-use-loading-dots className={`${styles.gameContainer} ${classes} ${hasFlair ? styles.hasFlair : ''}` }>
        <Link
          data-ga-click-delay
          href={ linkUrl }
        >
          <Image
            src={ imageUrl || singleTransparentPixel }
            alt={ alt }
            width={908}
            height={510}
            sizes={ sizes || '300px' }
            className={ classes }
          />
        </Link>
      </div>
      { gameItem.flair?.[0] && displayFlair(gameItem.flair?.[0]) }
    </article>
  );
};
export default GameCard;
