import React from 'react';
import { Asset, Maybe } from '@/types/pbskids-graph';
import styles from './Pagination.module.scss';

interface Props {
  centered?: boolean;
  current?: number;
  interactive?: boolean;
  slides?: Maybe<Asset>[]
  wrapperStyles?: string;
}

const Pagination: React.FC<Props> = ({ centered = false, current = 0, interactive = false, slides, wrapperStyles }) => {
  const allWrapperStyles = styles.paginationWrapper + ' ' + (centered ? styles.paginationWrapperCentered : '') + ' ' + wrapperStyles;

  if (!slides || slides.length === 0) return null;

  return (
    <div
      data-pbsk-component-pagination
      aria-hidden={!interactive}
      className={allWrapperStyles}
    >
      <ul className={styles.pagination}>
        { slides.map((slide, index) => (
          <li
            key={index}
            className={`${styles.dot} ${index === current ? styles.currentDot : ''}`}
          >
            <span className='sr-only'>slide {index + 1}{index === current ? ' current' : ''}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
